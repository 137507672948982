<template>
    <div>
        <div v-if="type == 0 && (skuList.length > 0 || spuList.length > 0)">
            <p style="color:red;font-size: 16px;font-weight: 700;">提示！</p>
            <div v-if="skuList.length > 0">
                <span
                    style="color:#333;font-size: 15px;margin-bottom: 10px;display: inline-block;">当前商品在以下spu中正在使用</span>
                <a-table bordered :columns="columnsSku" :dataSource="skuList" :loading="skuLoading" :pagination="false">
                    <template slot="pic" slot-scope="record">
                        <img :src="record" style="width:70px;" />
                    </template>
                </a-table>
                <div class="page-box">
                    <a-pagination :total="skuListTotal" :current="skuListParams.page + 1" :pageSize="skuListParams.size"
                        @change="handlePageSkuChange" @showSizeChange="onShowSizeSkuChange" show-size-changer />
                </div>
            </div>

            <div v-if="spuList.length > 0">
                <span style="color:#333;font-size: 15px;margin-bottom: 10px;display: inline-block;">当前商品在以下{{ spuTotal
                    }}个组合中正在使用</span>
                     <span style="color:red;font-size: 15px;margin-bottom: 10px;display: inline-block;" v-if="skuList[0].specNormalCount == 1">,若下架，相关商品均无法购买</span>
                <a-table bordered :columns="columnsSpu" :dataSource="spuList" :loading="spuLoading" :pagination="false">
                    <template slot="pic" slot-scope="record">
                        <img :src="record" style="width:70px;" />
                    </template>
                </a-table>
                <div class="page-box">
                    <a-pagination :total="spuTotal" :current="spuParams.page + 1" :pageSize="spuParams.size"
                        @change="handlePageSpuChange" @showSizeChange="onShowSizeSpuChange" show-size-changer />
                </div>
            </div>
        </div>
        <div v-if="type == 1 && spuGGList.length > 0">
            <p style="color:red;font-size: 16px;font-weight: 700">提示！</p>
            <p style="color:#333;font-weight: 700;">【商品名称】{{ prodName }}</p>
            <div v-for="item in spuGGList" :key="item.id">
                <p>【规格】{{ item.name }}</p>
               <div >
                    <span style="color:#333;font-size: 15px;margin-bottom: 10px;display: inline-block;">
                        当前商品在以下{{ item.spuTotal }}个组合中正在使用
                    </span>
                    <span v-if="item.specNormalCount == 1" style="color:red;font-size: 15px;margin-bottom: 10px;display: inline-block;">,若下架，相关商品均无法购买</span>
                    <a-table :bordered="true" :columns="columnsSpu" :dataSource="item.spuList" :loading="item.loading"
                        :pagination="false">
                        <template v-slot:pic="record">
                            <img :src="record" style="width:70px;" />
                        </template>
                    </a-table>
                    <div class="page-box">
                        <a-pagination :total="item.spuTotal" :current="item.spuParams.page + 1"
                            :pageSize="item.spuParams.size" @change="handlePageSpusChange(item, $event)"
                            @showSizeChange="onShowSizeSpusChange(item, $event, $event)" />
                    </div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from '@/utils/request'

export default {
    props: {
        id: {
            type: String,
            defalut: ""
        }
    },
    data() {
        return {
            // 包含sku列表
            skuListParams: {
                page: 0,
                size: 10,
                type: 1 //商品 组合品 礼包 123
            },
            // 包含组合
            spuParams: {
                page: 0,
                size: 10,
                type: 2 //商品 组合品 礼包 123
            },
            columnsSku: [
                { title: '商品图片', dataIndex: 'pic', align: 'center', scopedSlots: { customRender: 'pic' } },
                { title: '商品名称', dataIndex: 'name', align: 'center', },
                { title: 'spu编码', dataIndex: 'spuCode', align: 'center', },
            ],
            skuListTotal: 0,
            skuList: [],
            columnsSpu: [
                { title: '序号', width: 80, customRender: (text, record, index) => index + 1, align: 'center' },
                { title: '组合图片', dataIndex: 'pic', align: 'center', scopedSlots: { customRender: 'pic' } },
                { title: '组合名称', dataIndex: 'name', align: 'center', },
                { title: '组合编码', dataIndex: 'spuCode', align: 'center', },
            ],
            spuTotal: 0,
            spuList: [],

            spuGGList: [],
            type: 0,
            prodName:""
        }
    },
    methods: {
        // 0sku 1spu
        init(type,name) {
            console.log(type,name)
            if(name){
                this.prodName = name
            }else {
                this.prodName = ""
            }
            this.type = type; 
            if (type == 0) {
                this.skuListParams = {
                    page: 0,
                    size: 10,
                    type: 1
                }
                this.spuParams = {
                    page: 0,
                    size: 10,
                    type: 2
                }
                this.skuList = []
                this.spuList = []
                this.skuListParams.goodsSpecId = this.id
                this.spuParams.goodsSpecId = this.id
                this.getSkuList()
                this.getSpuList()
            } else {
                // 判断是否有组合使用
                let checkProdShow = false;
                request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/goodsSpecList/' + this.id, 'post', {}).then(res => {
                    this.tableLoading = false;
                    if (res.data.code === 200) {
                        this.spuGGList = res.data.data;
                        // 使用 Promise.all() 来处理所有异步请求
                        const spuRequests = this.spuGGList.map(item => {
                            item.spuParams = {
                                page: 0,
                                size: 10,
                                goodsSpecId: item.id,
                                type: 2
                            };
                            // 返回请求的 Promise，稍后用 Promise.all() 处理
                            return request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/relationGoods', 'post', item.spuParams)
                                .then(ress => {
                                    if (ress.data.code === 200) {
                                        const data = ress.data.data;
                                        // 使用 Vue.set 确保更新的响应式
                                        this.$set(item, 'spuList', data.data);
                                        this.$set(item, 'spuParams', {
                                            page: data.page,
                                            size: data.size,
                                            goodsSpecId: item.id,
                                            specNormalCount:item.specNormalCount,
                                            type: 2
                                        });
                                        console.log(data,'data')
                                        if(data.total > 0) {
                                            checkProdShow = true;
                                        }
                                        this.$set(item, 'spuTotal', data.total);
                                    } else {
                                        this.$message.error(ress.data.message);
                                    }
                                })
                                .catch(err => {
                                    this.$message.error('请求失败: ' + err.message);
                                });
                        });

                        // 等待所有请求完成后执行更新
                        Promise.all(spuRequests)
                            .then(() => {
                                // 所有请求完成，视图更新
                                console.log('所有请求完成', this.spuGGList);
                                this.$emit('checkProd',checkProdShow)
                            })
                            .catch(err => {
                                console.error('处理异步请求时出错', err);
                            });
                    } else {
                        this.$message.error(res.data.message);
                    }
                }).catch(err => {
                        this.$message.error('请求失败: ' + err.message);
                });
            }

        },
        // 获取sku
        getSkuList() {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/relationGoods', 'post', this.skuListParams).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.skuList = data.data;
                    this.skuListParams.page = data.page;
                    this.skuListParams.size = data.size;
                    this.skuListTotal = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 获取spu
        getSpuList() {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/relationGoods', 'post', this.spuParams).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.spuList = data.data;
                    this.spuParams.page = data.page;
                    this.spuParams.size = data.size;
                    this.spuTotal = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handlePageSkuChange(page) {
            this.skuListParams.page = page - 1;
            this.currentSkuPage = page;
        },
        handlePageSpuChange(page) {
            this.spuParams.page = page - 1;
            this.currentSpuPage = page;
        },
        onShowSizeSkuChange(current, pageSize) {
            this.skuListParams.size = pageSize;
            this.getSkuList()
        },
        onShowSizeSpuChange(current, pageSize) {
            this.spuParams.size = pageSize;
            this.getSpuList()
        },
        // 处理分页变化
        handlePageSpusChange(it, page) {
            // 找到对应的 item
            const item = this.spuGGList.find(i => i.id === it.id);
            if (item) {
                console.log(item,page,'item')
                // 更新对应 item 的分页信息
                item.spuParams.page = page - 1; // 转换为从 0 开始的分页
                // 重新请求该 item 的数据
                this.fetchSpuData(item);
            }
        },

        // 处理每页显示条数变化
        onShowSizeSpusChange(itemId, current, size) {
            // 找到对应的 item
            const item = this.spuGGList.find(i => i.id === itemId);
            if (item) {
                // 更新对应 item 的分页大小
                item.spuParams.size = size;
                // 重新请求该 item 的数据
                this.fetchSpuData(item);
            }
        },
        // 请求单个 item 的分页数据
        fetchSpuData(item) {
            // 设置该 item 的 loading 为 true
            item.loading = true;

            // 请求对应 item 的数据
            request(process.env.VUE_APP_API_BASE_URL + 'srm/goods/v2/relationGoods', 'post', item.spuParams)
                .then(ress => {
                    item.loading = false; // 请求完成后设置 loading 为 false
                    if (ress.data.code === 200) {
                        const data = ress.data.data;
                        // 使用 Vue.set 确保更新的响应式
                        this.$set(item, 'spuList', data.data);
                        this.$set(item, 'spuTotal', data.total);
                    } else {
                        this.$message.error(ress.data.message);
                    }
                })
                .catch(err => {
                    item.loading = false;
                    this.$message.error('请求失败: ' + err.message);
                });
        }
    }
}
</script>

<style>
.page-box {
    display: flex;
    justify-content: right;
    margin-top: 10px;
}
</style>