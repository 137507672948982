// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView'),
  page: () => import('@/layouts/PageView')
}

// 路由组件注册
const routerMap = {
  login: {
    authority: '*',
    path: '/login',
    component: () => import('@/pages/login')
  },
  root: {
    path: '/',
    name: '登录页',
    redirect: '/login',
    component: view.tabs
  },
  dashboard: {
    name: '工作台',
    icon: 'dashboard',
    component: view.blank
  },
  workplace: {
    name: '首页',
    component: () => import('@/pages/dashboard/workplace/WorkPlace')
  },
  warehouse: {
    name: '仓库管理',
    icon: 'bank',
    component: view.blank
  },
  warehouse_list: {
    name: '仓库列表',
    component: () => import('@/pages/srm_warehouse/index')
  },

  resources: {
    name: '资质管理',
    icon: 'safety-certificate',
    component: view.blank
  },
  resources_conpany: {
    name: '公司资质',
    component: () => import('@/pages/srm_resources/company/index')
  },
  resources_manufacturer:{
    name: '厂商资质',
    component: () => import('@/pages/srm_resources/manufacturer/index')
  },
  resources_brand:{
    name: '品牌资质',
    component: () => import('@/pages/srm_resources/brand/index')
  },
  resources_credentia:{
    name: '商品资质',
    component: () => import('@/pages/srm_resources/credentia/index')
  },
  resources_classify:{
    name: '分类资质',
    component: () => import('@/pages/srm_resources/classify/index')
  },
  product:{
    name: '我的商品',
    icon: 'gift',
    component: view.blank
  },
  product_spu:{
    name: 'spu商品',
    component: () => import('@/pages/srm_product/spu/index')
  },
  spuCombination:{
    name: '组合商品',
    component: () => import('@/pages/srm_product/spuCombination/index')
  },
  product_spuCombination_form:{
    name: 'spu组合商品',
    component: () => import('@/pages/srm_product/spuCombination/components/spuCom_form')
  },
  product_spuCombination_formEdit:{
    name: '编辑spu组合商品',
    component: () => import('@/pages/srm_product/spuCombination/components/spuCom_form')
  },
  product_spu_form:{
    name: '新增spu商品',
    component: () => import('@/pages/srm_product/spu/components/spu_form')
  },
  product_spu_formEdit:{
    name: '编辑spu商品',
    component: () => import('@/pages/srm_product/spu/components/spu_form')
  },
  product_sku_formDetails:{
    name: 'sku商品',
    component: () => import('@/pages/srm_product/sku/components/sku_form')
  },
  product_spu_formDetails:{
    name: '商品详情',
    component: () => import('@/pages/srm_product/spu/components/spu_form')
  },
  product_sku:{
    name: 'sku商品',
    component: () => import('@/pages/srm_product/sku/index')
  },
  srm_orders:{
    name: '订单管理',
    icon: 'unordered-list',
    component: view.blank    
  },
  srm_orders_list:{
    name: '普通订单',
    component: () => import('@/pages/srm_orders/list/index')
  },
  srm_deliveryRules: {
    name: '配送规则',
    icon: 'file-search',
    component: view.blank
  },
  srm_deliveryRules_regionLimit: {
    name: '地区限制',
    component: () => import('@/pages/srm_deliveryRules/regionLimit/index')
  },
  srm_mine: {
    name: '个人中心',
    icon: 'user',
    component: view.blank
  },
  srm_exportTaskList:{
    name: '导出任务列表',
    component: () => import('@/pages/srm_user/ExportTask')
  },


 
}
export default routerMap

